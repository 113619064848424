var _defineProperty = require("/tmp/build_faa35ed8/services/app/node_modules/@babel/runtime/helpers/defineProperty");

var _configurations;

var MARKETPLACE_SANDBOX = "givsly-sandbox";
var MARKETPLACE_TEST = "givsly-test";
var MARKETPLACE_PRODUCTION = "givsly";
var MARKETPLACES = [MARKETPLACE_PRODUCTION, MARKETPLACE_SANDBOX, MARKETPLACE_TEST];
var ROLE_PRODUCTION = "production";
var ROLE_PRE_PRODUCTION = "pre-production";
var ROLE_STAGING = "staging";
var ROLE_TEST = "test";
var ROLE_DEVELOPMENT = "development";
var ROLE_SANDBOX = "sandbox";
var ROLES = [ROLE_DEVELOPMENT, ROLE_PRE_PRODUCTION, ROLE_PRODUCTION, ROLE_SANDBOX, ROLE_STAGING, ROLE_TEST];
var configurations = (_configurations = {}, _defineProperty(_configurations, MARKETPLACE_SANDBOX, {
  name: "Givsly Sandbox",
  roles: [ROLE_SANDBOX],
  url: "https://givsly-sandbox.herokuapp.com"
}), _defineProperty(_configurations, MARKETPLACE_TEST, {
  name: "Givsly",
  roles: [ROLE_DEVELOPMENT, ROLE_TEST, ROLE_STAGING],
  url: "https://givsly-staging.herokuapp.com"
}), _defineProperty(_configurations, MARKETPLACE_PRODUCTION, {
  name: "Givsly",
  roles: [ROLE_PRODUCTION, ROLE_PRE_PRODUCTION],
  url: "https://www.givsly.com"
}), _configurations);
var unknownConfiguration = {
  name: "Unknown",
  roles: ["unknown"],
  url: "https://www.givsly.com"
};
/**
 * Returns the marketplace configuration for the given marketplace name
 *
 * @param marketplace
 * @returns {Object}
 */

var getMarketplaceConfig = function getMarketplaceConfig(marketplace) {
  if (Object.keys(configurations).includes(marketplace)) {
    return configurations[marketplace];
  }

  return unknownConfiguration;
};
/**
 * Gets the marketplace name based on the given role
 *
 * @param role
 * @returns {string}
 */


var getMarketplaceByRole = function getMarketplaceByRole(role) {
  return Object.keys(configurations).find(function (k) {
    return configurations[k].roles.includes(role);
  });
};
/**
 * Directly retrieves the marketplace config based on the given role
 *
 * @param role
 * @returns {Object}
 */


var getMarketplaceConfigByRole = function getMarketplaceConfigByRole(role) {
  return getMarketplaceConfig(getMarketplaceByRole(role));
};

module.exports = {
  MARKETPLACE_SANDBOX: MARKETPLACE_SANDBOX,
  MARKETPLACE_TEST: MARKETPLACE_TEST,
  MARKETPLACE_PRODUCTION: MARKETPLACE_PRODUCTION,
  MARKETPLACES: MARKETPLACES,
  ROLE_PRODUCTION: ROLE_PRODUCTION,
  ROLE_PRE_PRODUCTION: ROLE_PRE_PRODUCTION,
  ROLE_STAGING: ROLE_STAGING,
  ROLE_TEST: ROLE_TEST,
  ROLE_DEVELOPMENT: ROLE_DEVELOPMENT,
  ROLE_SANDBOX: ROLE_SANDBOX,
  ROLES: ROLES,
  getMarketplaceConfig: getMarketplaceConfig,
  getMarketplaceByRole: getMarketplaceByRole,
  getMarketplaceConfigByRole: getMarketplaceConfigByRole
};