import axios from 'axios';
import { getBaseHeaders } from './api';

const API_ENDPOINT = process.env.REACT_APP_OUTREACH_API;

/**
 * Gets the API request headers for the current user
 *
 * @param {object} ensuredCurrentUser
 * @return {{Authorization: string, Marketplace: string, UserID: string, Accepts: string}}
 */
const getHeaders = (ensuredCurrentUser = null) => {
  return getBaseHeaders(
    ensuredCurrentUser ? ensuredCurrentUser.id.uuid : 'guest',
    ensuredCurrentUser ? ensuredCurrentUser.attributes.profile.privateData.paymentSecret : 'guest'
  );
};

/**
 * Creates a new offer
 *
 * @param {object} ensuredCurrentUser
 * @param {object} data The offer data
 * @return {Promise<boolean>}
 */
export const createOffer = async (ensuredCurrentUser, data) => {
  const res = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/v1/offers/`,
    headers: getHeaders(ensuredCurrentUser),
    data,
  });
  return res.data;
};

/**
 * Creates a new outreach request
 *
 * @param {object} data
 * @return {Promise<void>}
 */
export const createRequest = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/v1/requests/`,
    headers: getHeaders(),
    data,
  });
  return res.data;
};

/**
 * Deletes the given offer
 *
 * @param {object} ensuredCurrentUser
 * @param {number} offerId
 * @return {Promise<boolean>}
 */
export const deleteOffer = async (ensuredCurrentUser, offerId) => {
  await axios({
    method: 'DELETE',
    url: `${API_ENDPOINT}/v1/offers/${offerId}`,
    headers: getHeaders(ensuredCurrentUser),
  });
  return true;
};

/**
 * Deletes the given request
 *
 * @param {object} ensuredCurrentUser
 * @param {number} offerId
 * @return {Promise<boolean>}
 */
export const deleteRequest = async (ensuredCurrentUser, offerId) => {
  await axios({
    method: 'DELETE',
    url: `${API_ENDPOINT}/v1/request/${offerId}`,
    headers: getHeaders(ensuredCurrentUser),
  });
  return true;
};

/**
 * Gets all offers for the current user from the outreach API
 *
 * @param {string} id
 * @return {Promise<any|*[]>}
 */
export const getOffer = async (id) => {
  const res = await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/v1/offers/${id}`,
    headers: getHeaders(),
  });

  return res ? res.data : [];
};

/**
 * Gets all offers for the current user from the outreach API
 *
 * @param {object} ensuredCurrentUser
 * @return {Promise<any|*[]>}
 */
export const queryOffers = async (ensuredCurrentUser) => {
  const res = await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/v1/offers`,
    headers: getHeaders(ensuredCurrentUser),
  });

  return res ? res.data : [];
};

/**
 * Gets the target outreach request from the outreach API
 *
 * @param {object} ensuredCurrentUser
 * @param {string} id
 * @return {Promise<any|*[]>}
 */
export const getRequest = async (ensuredCurrentUser, id) => {
  const res = await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/v1/requests/${id}`,
    headers: getHeaders(ensuredCurrentUser),
  });

  return res ? res.data : [];
};

/**
 * Gets all requests for the current user from the outreach API
 *
 * @param {object} ensuredCurrentUser
 * @return {Promise<any|*[]>}
 */
export const queryRequests = async (ensuredCurrentUser) => {
  const res = await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/v1/requests`,
    headers: getHeaders(ensuredCurrentUser),
  });

  return res ? res.data : [];
};

/**
 * Updates the given offer
 *
 * @param {object} ensuredCurrentUser
 * @param {object} data
 * @return {Promise<any|*[]>}
 */
export const updateOffer = async (ensuredCurrentUser, data) => {
  const id = data.id;
  delete data.id;
  const res = await axios({
    method: 'PUT',
    url: `${API_ENDPOINT}/v1/offers/${id}`,
    headers: getHeaders(ensuredCurrentUser),
    data,
  });

  return res ? res.data : [];
};

/**
 * Updates the given request
 *
 * @param {object} ensuredCurrentUser
 * @param {object} data
 * @return {Promise<any|*[]>}
 */
export const updateRequest = async (ensuredCurrentUser, data) => {
  const id = data.id;
  delete data.id;
  const res = await axios({
    method: 'PUT',
    url: `${API_ENDPOINT}/v1/requests/${id}`,
    headers: getHeaders(ensuredCurrentUser),
    data,
  });

  return res ? res.data : [];
};

/**
 * Image upload endpoint
 *
 * @param {object} ensuredCurrentUser
 * @param {object} obj
 * @return {Promise<any|*[]>}
 */
export const uploadImage = async (ensuredCurrentUser, obj) => {
  const res = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/v1/image-upload`,
    headers: getHeaders(ensuredCurrentUser),
    data: obj,
  });

  return res ? res.data : [];
};

/**
 * query unsplash API
 *
 * @param {object} ensuredCurrentUser
 * @param {object} obj
 * @return {Promise<any|*[]>}
 */
export const queryUnsplashAPI = async (ensuredCurrentUser, obj) => {
  const res = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/v1/unsplash`,
    headers: getHeaders(ensuredCurrentUser),
    data: obj,
  });

  return res ? res.data : [];
};

/**
 * query unsplash API for default givsly image collection
 *
 * @param {object} ensuredCurrentUser
 * @param {object} obj
 * @return {Promise<any|*[]>}
 */
export const queryUnsplashAPIforGivslyCollection = async (ensuredCurrentUser, obj) => {
  const res = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/v1/unsplash/givsly`,
    headers: getHeaders(ensuredCurrentUser),
    data: obj,
  });

  return res ? res.data : [];
};

/**
 * query unsplash API for default givsly image collection
 *
 * @param {object} ensuredCurrentUser
 * @param {object} obj
 * @return {Promise<any|*[]>}
 */
export const unsplashDownload = async (ensuredCurrentUser, obj) => {
  const res = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/v1/unsplash/download`,
    headers: getHeaders(ensuredCurrentUser),
    data: obj,
  });

  return res ? res.data : [];
};

/**
 * @typedef {{name:string,regionCode:string,regionType: "region"|"sub-region"}} Region
 */

/**
 *
 * @param {*} ensuredCurrentUser
 * @param {AbortSignal} signal
 * @returns {Promise<Region[]>}
 */
export const fetchRegions = async (ensuredCurrentUser, signal) => {
  const res = await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/v1/elastic/continents`,
    headers: getHeaders(ensuredCurrentUser),
    signal,
  });

  return res ? res.data : [];
};

/**
 *
 * @param {*} ensuredCurrentUser
 * @param {Region} region
 * @param {AbortSignal} signal
 * @returns {Promise<Array<{}>>}
 */
export const fetchCountries = async (ensuredCurrentUser, region, signal) => {
  const url = new URL(`${API_ENDPOINT}/v1/elastic/countries`);
  if (region) {
    url.searchParams.append(region.regionType, region.regionCode);
  }

  const res = await axios({
    method: 'GET',
    url: url.toString(),
    headers: getHeaders(ensuredCurrentUser),
    signal,
  });

  return res ? res.data : [];
};

/**
 *
 * @param {*} ensuredCurrentUser
 * @param {string} countryAlpha2
 * @param {AbortSignal} signal
 * @returns {Promise<Array<{}>>}
 */
export const fetchStates = async (ensuredCurrentUser, countryAlpha2, signal) => {
  const url = new URL(`${API_ENDPOINT}/v1/elastic/states`);
  url.searchParams.append('alpha-2', countryAlpha2);
  const res = await axios({
    method: 'GET',
    url: url.toString(),
    headers: getHeaders(ensuredCurrentUser),
    signal,
  });

  return res ? res.data : [];
};

export const searchIab = async (ensuredCurrentUser, searchString = '*', signal) => {
  const res = await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/v1/elastic/iab?search=${searchString}`,
    headers: getHeaders(ensuredCurrentUser),
    signal,
  });

  return res ? res.data : [];
};

export const validateCognitoUser = (email, tenant_id) =>
  axios({
    method: 'POST',
    headers: getHeaders(),
    url: `${API_ENDPOINT}/v1/tenant-manager/verify-email`,
    data: { email, tenant_id },
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((axiosError) => {
      if (axiosError && 'reponse' in axiosError) {
        const {
          response: { data, status, statusText },
        } = axiosError;
        const message = data && data.message;
        const name = (data && data.name) || statusText;
        if (message && name) {
          return Promise.reject({ message, name, status, statusText });
        }
      }
      return Promise.reject(axiosError);
    });

export const syncUserToCognito = async (
  ensuredCurrentUser,
  cognito_username,
  cognito_tenant_id,
  sharetribe_id,
  password
) => {
  const response = await axios({
    method: 'POST',
    headers: getHeaders(ensuredCurrentUser),
    url: `${API_ENDPOINT}/v1/tenant-manager/sync/${cognito_username}`,
    data: { sharetribe_id, password, cognito_tenant_id },
  });

  return response;
};

export const syncNpoToElastic = async (ensuredCurrentUser, listing_id, dirty_media, dirty_info) => {

  // Check if listing_id is a non-empty string
  if (typeof listing_id !== 'string' || listing_id.trim() === '') {
    throw new Error('Invalid listing_id: must be a non-empty string');
  }
  
  // Check if email is a valid email string
  const email = ensuredCurrentUser.attributes.email;
  if (typeof email !== 'string' || email.trim() === '') {
    throw new Error('Invalid email: must be a non-empty email');
  }

  axios({
    method: 'POST',
    headers: getHeaders(ensuredCurrentUser),
    url: `${API_ENDPOINT}/v1/npo-indexer/sync`,
    data: { 
      listing_id: listing_id, 
      email: email,
      update_dynamo: true
    },
  });
}
